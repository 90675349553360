import { global } from '../../global.js';
import { FASTElement, css, html, customElement } from '@microsoft/fast-element';
const styles = [
  global,
  css`
    /* tete */
    header {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      position: sticky;
      z-index: 100;
      top: 0;
      width: 100%;
      background: transparent;
    }

    /* navbar */
    nav {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      width: 85%;
      margin: 1%;
      padding: .5%;

      background: #000;
      border: 1px solid #fff;
    }
    .navMenu {
      justify-content: end;

      width: 50%;
    }
  
    .navMenu a {
      font-size: clamp(12px, 2.5vw, 18px);
      text-align: center;
    }

    /* logo (cl) */
    
    .logoLink {
      text-align: start;
      width: 25%;
    }
  `,
];
const kMenuItems: Array<any> = [
  {
    title: 'Contact',
    link: '/contact',
  },
];

const template = html<AppHeader>`
  <header>
    <nav>
      <a href="/home" class="logoLink">
        <img class="gros" src="/assets/brand-kit/clx_OP.svg" />
      </a>

      <ul class="navMenu">
        ${kMenuItems.map(
          (item) =>
            ` <li> 
          <a title="nav" href="${item.link}"> ${item.title} </a> 
          <img src="" />
          </li> `
        ).join('')}
        
      </ul>
    </nav>
  </header>
`;

@customElement({ name: 'app-header', template, styles })
export class AppHeader extends FASTElement {}
