import landing from './landing.json';
import portfolio from './portfolio.json';
import footer from './footer.json';

// export type PortfolioItem = {
//   project: string;
//   position: string;
//   logo: string;
//   caroImgs: Array<string>;
// };
export class PortfolioItem {
  public key: string;
  constructor(
    public project: string,
    public logo: string,
  ) {
    this.key = project.replace(/ /g, '-').replace(/'/g, '').toLowerCase();
  }
}
class PortfolioData extends Array<PortfolioItem> {
  dict: { [key: string]: number } = {};
  constructor() {
    super();
    for (let i = 0; i < portfolio.length; i++) {
      const item = portfolio[i];
      const pi = new PortfolioItem(
        item.project,
        item.logo,
      );
      this.push(pi);
      this.dict[pi.key] = i;
    }
  }
  get(key: string): PortfolioItem {
    return this[this.dict[key]];
  }
  mapDict<T>(fn: (pi: PortfolioItem, i: number) => T): { [key: string]: T } {
    const dict: { [key: string]: T } = {};
    for (let i = 0; i < this.length; i++) {
      const pi = this[i];
      dict[pi.key] = fn(pi, i);
    }
    return dict;
  }
}
export const kPortfolioData = new PortfolioData();
export const kSegmentItems = landing;
export const kFooterItems = footer;