import { FASTElement, html, css, customElement } from '@microsoft/fast-element';
import { global } from '../../global.js';
import { kSegmentItems } from '../data/index.js';
import '../components/body.js';



const kSegment2 = html`
  <fieldset class="devMenu">
    <legend><h2>Dev Leads</h2></legend>
    <div>
      ${kSegmentItems.seg2.map((item) => {
        return `
          <span>
            <a target="_blank" href="${item.href}">
              <img class="gros" src="${item.logo}" />
              <h2>${item.name}</h2>
              <p>${item.title}</p>
            </a>
          </span>
        `;
      })
      .join('')}
    </div>
  </fieldset>`;
  
const kSegment3 = html`
  <fieldset class="prjxMenu">
    <legend><h3>Active Projects</h3></legend>
    <div>
    ${kSegmentItems.seg3.map((item) => {
      return `
        <span>
          <a target="_blank" href="${item.href}">
            <img class="gross" src="${item.img}" />
          </a>
        </span>
      `;
    })
    .join('')}
    </div>
  </fieldset> `;

const styles = [
  global,
  css`
  .segmentMenu h3, p {
    padding: 1%;
  }
  .segmentMenu li {
    width: 35%;
  }

  .prjxMenu {
    flex-flow: row wrap;
    width: 75%;
  }
  .prjxMenu div {
    width: 100%;
  }
  .prjxMenu span {
    width: 25%;
  }
    ul {
      width: 100%;
      margin: 1% 0;
      padding: 1% 0;
      gap: 1%;
    }
    ul li {
      padding: 2.5%;
      width: 25%;
    }

    div span {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      width: 35%;
    }
    div {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    @media (max-width: 768px) {
    }
  `,
];

const template = html`
  <app-body>
    <section>
      <h2>Building just got better.</h2>
      <p>We aim to
                help you improve productivity so
                that you can maximize your resources, stay competitive, and
                achieve long-term growth through the use of progressive web
                apps and software.</p>
      ${kSegment2} 
      ${kSegment3}
      <h2>Unlock the true productivity of your business.</h2>
    </section>
  </app-body>
`;

@customElement({
  name: 'app-landing',
  template,
  styles,
})
export class AppLanding extends FASTElement {}
